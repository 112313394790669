import { type CompanyConfig, type Blog, type Company, type CompanyCredit, type UpgradeCreditsRequest } from '../../types'
import css from './Columns.module.css'
import React from 'react'
import { Box, IconButton } from '@mui/material'
import { Edit, Delete, TextSnippet, VpnKey } from '@mui/icons-material'
import { type ColumnDef } from '@tanstack/react-table'
import moment from 'moment'

const Cell: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <div className={css.cell}>
      {items.map((item) => (
        <span key={item}>{item}</span>
      ))}
    </div>
  )
}

const getCreditsAsRows = (credit: CompanyCredit): string[] => [`Ideas ${credit.ideas_used}/${credit.ideas_limit}`, `Frames ${credit.frames_used}/${credit.frames_limit}`, `Posts ${credit.posts_used}/${credit.posts_limit}`]
const getUpgradeCreditsAsRows = (upgradeCredit: UpgradeCreditsRequest): string[] => [
  upgradeCredit?.ideas ? `Ideas: ${upgradeCredit?.ideas}` : '',
  upgradeCredit?.posts ? `Posts: ${upgradeCredit?.posts}` : '',
  upgradeCredit?.email ? `Email: ${upgradeCredit?.email}` : '',
  upgradeCredit?.created_at ? `Date: ${moment(upgradeCredit?.created_at).format('YYYY-MM-DD HH:mm:ss')}` : ''
]

const getConfigAsRows = (config: CompanyConfig): string[] => [
  `Provider: ${config?.ai_provider ? `${config.ai_provider} (custom)` : `${config.ai_provider_default} (default)`}`,
  `Model: ${config?.ai_model ? `${config.ai_model} (custom)` : `${config.ai_model_default} (default)`}`,
  `Temperature: ${config?.ai_temperature ? `${config.ai_temperature} (custom)` : `${config.ai_temperature_default} (default)`}`
]

const columns: Array<ColumnDef<Company>> = [
  {
    accessorKey: 'id',
    header: 'Id'
  },
  {
    accessorKey: 'user.email',
    header: 'User'
  },
  {
    accessorKey: 'managers.cs_manager.email',
    header: 'CS Manager'
  },
  {
    accessorKey: 'cs_stage',
    header: 'Stage'
  },
  {
    accessorKey: 'blogs',
    header: 'Blogs',
    cell: (cell) => {
      const strings = cell.getValue<Blog[]>().map((b) => b.name) ?? []
      return <Cell items={strings} />
    }
  },
  {
    accessorKey: 'user.account_status',
    header: 'Status'
  },
  {
    accessorKey: 'credit',
    header: 'Credit',
    cell: (cell) => <Cell items={getCreditsAsRows(cell.getValue() as CompanyCredit)} />
  },
  {
    accessorKey: 'upgrade_credits_request',
    header: 'Upgrade Credits',
    cell: (cell) => <Cell items={getUpgradeCreditsAsRows(cell.getValue() as UpgradeCreditsRequest)} />
  },
  {
    accessorKey: 'config',
    header: 'Config',
    cell: (cell) => <Cell items={getConfigAsRows(cell.getValue() as CompanyConfig)} />
  },
  {
    accessorFn: (row) => row.id,
    header: 'Actions',
    cell: (cell) => (
      <Box>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.edit?.(cell.getValue() as number)
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.remove?.(cell.getValue() as number)
          }}
        >
          <Delete />
        </IconButton>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.requests?.(cell.getValue() as number)
          }}
        >
          <TextSnippet />
        </IconButton>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.keywords?.(cell.getValue() as number)
          }}
        >
          <VpnKey />
        </IconButton>
      </Box>
    )
  }
]

export default columns
