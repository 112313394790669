import instance from './client'
import { type AI_PROVIDER, type BaseError, type Template } from '../types'
import { type AxiosError } from 'axios'
import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'

export const useTemplate = (id?: number): UseQueryResult<Template, BaseError> => {
  return useQuery<unknown, BaseError, Template>({
    queryKey: ['template', id],
    queryFn: async () => await instance.get('/templates/' + id).then((data) => data.data),
    enabled: !!id
  })
}

export const useTemplateByProvider = (provider: AI_PROVIDER): UseQueryResult<Template, BaseError> => {
  return useQuery<unknown, BaseError, Template>({
    queryKey: ['template-by-provider', provider],
    queryFn: async () => await instance.get('/templates/provider/' + provider).then((data) => data.data),
    enabled: !!provider
  })
}

interface UpdateInput extends Partial<Template> {
  id: number
}

export const useUpdateTemplate = (): UseMutationResult<Template, AxiosError, UpdateInput> => {
  const queryClient = useQueryClient()
  return useMutation<Template, AxiosError, UpdateInput>({
    mutationKey: ['update-template'],
    mutationFn: async ({ id, ...input }) => await instance.patch(`/templates/${id}`, input).then((data) => data.data),
    onSuccess: (data) => {
      queryClient.setQueryData(['template', data.id], data)
      queryClient.setQueryData(['template-by-provider', data.provider], data)
    }
  })
}

export const useUpdateTemplateByProvider = (provider: AI_PROVIDER): UseMutationResult<Template, AxiosError, UpdateInput> => {
  const queryClient = useQueryClient()
  return useMutation<Template, AxiosError, UpdateInput>({
    mutationKey: ['update-template-by-provider'],
    mutationFn: async ({ id, ...input }) => await instance.patch(`/templates/provider/${provider}`, input).then((data) => data.data),
    onSuccess: (data) => {
      queryClient.setQueryData(['template-by-provider', provider], data)
      queryClient.setQueryData(['template', data.id], data)
    }
  })
}
