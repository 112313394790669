import React, { useState } from 'react'
import Empty from '../Empty/Empty'
import { useTemplateByProvider as useTemplateApi } from '../../api/template'
import ProviderTemplate from './components/Template/ProviderTemplate'
import { AI_PROVIDER } from '../../types'
import { useDebounce } from 'use-debounce'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import useTemplate from '../../hooks/useTemplate'
import Spinner from '../../components/spinner/Spinner'
import Loader from '../../components/loader/Loader'

const Container: React.FC = () => {
  const [aiProvider, setAiProvider] = useState(AI_PROVIDER.OPENAI)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const { data: template, isLoading, error } = useTemplateApi(aiProvider)
  const [debouncedSearchTerm] = useDebounce(searchTerm, 200)
  const templateParsed = useTemplate({ data: template, filter: debouncedSearchTerm })
  const areControlsDisabled = isSubmitting || isLoading

  return (
    <div style={{ width: '100%' }}>
      <PageHeaderText>Templates</PageHeaderText>
      {isSubmitting ? <Loader /> : null}
      <Box display="flex" paddingInline={2} paddingBlock={4}>
        <Stack flex={4}>
          <Box display="flex">
            <FormControl sx={{ width: '200px' }}>
              <InputLabel id="select-label">Provider</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                value={aiProvider}
                label="Provider"
                size="small"
                onChange={(e) => {
                  setAiProvider(e.target.value as AI_PROVIDER)
                }}
                disabled={areControlsDisabled}
              >
                {Object.entries(AI_PROVIDER).map(([providerLabel, providerValue]) => (
                  <MenuItem key={providerValue} value={providerValue}>
                    {providerLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField disabled={areControlsDisabled} style={{ width: '40%', marginLeft: '15px', alignSelf: 'flex-end' }} size="small" id="search" name="search" label="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Box>
          {isLoading ? <Spinner marginY={8} /> : template ? <ProviderTemplate key={aiProvider} templateParsed={templateParsed} onSubmitChange={(v) => setIsSubmitting(v)} /> : <Empty message={error?.response?.data.message} />}
        </Stack>
      </Box>
    </div>
  )
}

export default Container
