import React, { useContext } from 'react'
import { useCompanyConfig, useUpdateCompanyConfig } from '../../api/company'
import MessageContext from '../../contexts/MessageContext'
import { AI_PROVIDER, type CompanyConfigFormik, type CompanyConfig as CompanyConfigType } from '../../types'
import Empty from '../Empty/Empty'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import { type FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import Spinner from '../../components/spinner/Spinner'
import CompanyConfigForm from '../../components/companyConfigForm/CompanyConfigForm'

const companyConfigSchema = Yup.object({
  ai_provider: Yup.string().oneOf(Object.values(AI_PROVIDER)).required(),
  ai_model: Yup.string().required(),
  ai_temperature: Yup.number().required().min(0).max(1)
})

interface CompanyConfigProps {
  config: CompanyConfigType
}

const CompanyConfig: React.FC<CompanyConfigProps> = ({ config }) => {
  const updateCompanyConfig = useUpdateCompanyConfig()
  const addMessage = useContext(MessageContext)

  const formik: FormikProps<CompanyConfigFormik> = useFormik<CompanyConfigFormik>({
    initialValues: {
      company_id: config.company_id,
      ai_provider: config.ai_provider,
      ai_model: config.ai_model,
      ai_temperature: config.ai_temperature,
      created_at: config.created_at,
      updated_at: config.updated_at
    },
    enableReinitialize: true,
    validationSchema: companyConfigSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      updateCompanyConfig
        .mutateAsync(values)
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex">
        <Stack spacing={2} width={'100%'}>
          <CompanyConfigForm formik={formik} config={config} />
          <Button disabled={formik.isSubmitting} type="submit" variant="contained" style={{ width: 230 }}>
            {formik.isSubmitting && <CircularProgress color="inherit" size={'1rem'} sx={{ marginRight: '0.5rem' }} />}Save
          </Button>
        </Stack>
      </Box>
    </form>
  )
}

const Container: React.FC<{ companyId: number }> = ({ companyId }) => {
  const { data, isLoading, error } = useCompanyConfig(companyId)
  const message = (error?.response?.data ?? error?.message ?? 'Wrong Company Id') as string
  if (isLoading) return <Spinner marginY={8} />
  if (data) return <CompanyConfig config={data} />
  return <Empty message={message} />
}
export default Container
