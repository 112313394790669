import React, { useMemo } from 'react'
import { type FormikProps } from 'formik'
import { AI_PROVIDER, type CompanyConfigFormik, type CompanyConfig } from '../../types'
import Select from '@mui/material/Select'
import { FormControl, InputLabel, MenuItem } from '@mui/material'
import TextField from '@mui/material/TextField'

interface CompanyConfigFormProps {
  formik: FormikProps<CompanyConfigFormik>
  config: CompanyConfig
}

const AI_PROVIDER_OPTIONS = {
  [AI_PROVIDER.OPENAI]: 'OpenAI',
  [AI_PROVIDER.ANTHROPIC]: 'Anthropic'
}

const AI_MODELS_BY_PROVIDER = {
  [AI_PROVIDER.OPENAI]: {
    'gpt-4o-mini': 'GPT-4o Mini',
    'gpt-4o': 'GPT-4o',
    'gpt-4-turbo': 'GPT-4 Turbo',
    'gpt-4': 'GPT-4',
    'gpt-3.5-turbo': 'GPT-3.5 Turbo'
  },
  [AI_PROVIDER.ANTHROPIC]: {
    'claude-3-5-sonnet-20240620': 'Claude 3.5 Sonnet',
    'claude-3-opus-20240229': 'Claude 3 Opus',
    'claude-3-sonnet-20240229': 'Claude 3 Sonnet',
    'claude-3-haiku-20240307': 'Claude 3 Haiku'
  }
}

const CompanyConfigForm: React.FC<CompanyConfigFormProps> = ({ formik, config }) => {
  const isCustomModel = useMemo(() => {
    return !Object.keys(AI_MODELS_BY_PROVIDER[formik.values.ai_provider] || {}).includes(formik.values.ai_model)
  }, [formik.values.ai_provider, formik.values.ai_model])

  const [aiModelType, setAiModelType] = React.useState(isCustomModel ? 'custom' : formik.values.ai_model)

  const handleChangeAIModel = (value: string): void => {
    const newModel = value

    setAiModelType(newModel)
    if (newModel !== 'custom') {
      formik.setFieldValue('ai_model', newModel)
    }
  }

  const handleChangeAIProvider = (value: string): void => {
    const newProvider = value as AI_PROVIDER

    formik.setFieldValue('ai_provider', newProvider)
    handleChangeAIModel(Object.keys(AI_MODELS_BY_PROVIDER[newProvider])[0])
  }

  return (
    <>
      <FormControl fullWidth error={formik.touched.ai_provider && !!formik.errors.ai_provider}>
        <InputLabel id="ai-provider-select-label">AI Provider</InputLabel>
        <Select
          fullWidth
          labelId="ai-provider-select-label"
          label={'AI Provider'}
          id="ai_provider"
          name="ai_provider"
          value={formik.values.ai_provider}
          disabled={formik.isSubmitting}
          onChange={(e) => handleChangeAIProvider(e.target.value)}
          onBlur={formik.handleBlur}
        >
          {Object.entries(AI_PROVIDER_OPTIONS).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
              {(config.ai_provider_custom && value === config.ai_provider_custom) ? ' (saved)' : ''}
              {(value === config.ai_provider_default) ? ' (default)' : ''}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth error={formik.touched.ai_model && !!formik.errors.ai_model}>
        <InputLabel id="ai-model-select-label">AI Model</InputLabel>
        <Select fullWidth labelId="ai-model-select-label" label={'AI Model'} id="ai_model_select" name="ai_model_select" value={aiModelType} disabled={formik.isSubmitting} onChange={(e) => handleChangeAIModel(e.target.value)}>
          {Object.entries(AI_MODELS_BY_PROVIDER[formik.values.ai_provider] || {}).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
              {(config.ai_model_custom && value === config.ai_model_custom) ? ' (saved)' : ''}
              {(value === config.ai_model_default) ? ' (default)' : ''}
            </MenuItem>
          ))}
          <MenuItem key={'custom'} value={'custom'}>
            Custom
          </MenuItem>
        </Select>
      </FormControl>
      {aiModelType === 'custom' && (
        <TextField
          fullWidth
          id="ai_model"
          name="ai_model"
          label="Custom AI Model"
          value={formik.values.ai_model}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.touched.ai_model && !!formik.errors.ai_model}
          helperText={formik.touched.ai_model && formik.errors.ai_model}
        />
      )}
      <FormControl fullWidth error={formik.touched.ai_temperature && !!formik.errors.ai_temperature}>
        <TextField
          fullWidth
          id="ai_temperature"
          name="ai_temperature"
          label="AI Temperature"
          type="number"
          value={formik.values.ai_temperature}
          placeholder={config.ai_temperature_default.toString() + ' (default)'}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.touched.ai_temperature && !!formik.errors.ai_temperature}
          helperText={formik.touched.ai_temperature && formik.errors.ai_temperature}
          inputProps={{
            step: 0.01,
            min: 0,
            max: 1
          }}
        />
      </FormControl>
    </>
  )
}

export default CompanyConfigForm
